

.topHeader {
    text-align: start;
    margin-top:10px;
    font-size: x-large;
    color: white;
    opacity: 0.7;
    font-family: Inter;
    font-weight: 700;
}
.App {
    font-family: sans-serif;
    text-align: center;
}
.titleOptions{
    color: white;
    display: flex;
    margin-bottom: 10px;
    align-items: flex-end;
}


.containerStyle{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.WhyUsLeftSide{

}
.WhyUsTitleText{
    text-align: center;
}


.WhyUsImage{
    margin-top: 10px;
    margin-left: 15px;

    background-size: cover;
}

.WhyUsGroupCheck{
    display: flex;
}

.WhyUsCheckPoint{
    background-color: #FFD563;

    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    flex-shrink: 0;

}

.imageStyle{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%) contrast(125%) sepia(20%) hue-rotate(220deg);
}

.title {
    height: 500px;
    color: white;

}
.ExperienceImage{
    margin-top: 30px;
    background-size: cover;
}

.ExperienceTextDate {
    font-weight: 500;
    color: black;
}

.ExperienceTextDescription{
    justifyItems: center;
    margin-left: 15px;
    text-align: justify;
}

.ExperienceContactButton{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    background-color: #FFD563;
}



.OurServicesTitleText{
    display: flex;
    color: black;
    justify-content: center;
    align-items: center;

}

.OurServicesImages {
    margin-top: 20px;
    background-size: cover;
    border-radius: 50%;
}

.OurServicesText{
    text-align: center;
    margin-top: 10px;
    padding: 0 5px;
}

.titleText {
    font-style: normal;
    font-weight: 800;
    line-height: 29px;
    color: white;
    font-family: 'Nunito', sans-serif; /* Define a fonte Nunito */

}

.titleSubText {
    font-family: 'Nunito', sans-serif; /* Define a fonte Nunito */
    margin-left: 40px;
    margin-right: 20px;
    margin-top: 35px;
    color: white;
    max-width: 80%; /* Limita a largura do texto */
}


